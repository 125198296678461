<template>
  <sdCards headless>
    <div class="bill" v-if="BillName">
      <a-statistic :class="`bill__amount _${BillName}`" :value="BillCount" :precision="2">
        <template #title>
          <router-link :to="'/account/' + AccountId + '/page/1'">
            <p class="bill__title">
              <sdFeatherIcons class="bill__icon" :type="BillIcon" size="18" />
              {{ i18n.t(`accounts.${BillName}Name`) }}
            </p>
          </router-link>
        </template>
        <template #suffix>
          <p class="bill__currency">{{ BillName !== 'token' ? 'USDT' : i18n.t('transactions.MTH') }}</p>
        </template>
      </a-statistic>
      <div class="bill__buttons" v-if="BillName === 'personal'">
        <sdButton class="bill__button" @click="openModal('deposit')" size="large" type="success" raised>
          {{ i18n.t('start.deposit') }}
        </sdButton>
        <sdButton class="bill__button" @click="openModal('withdrawal')" size="large" type="primary" raised>
          {{ i18n.t('start.withdrawal') }}
        </sdButton>
      </div>
      <div class="bill__buttons" v-else-if="BillName === 'partner'">
        <sdButton class="bill__button" size="large" @click="openModal('transfer')" type="success" raised>
          {{ i18n.t('start.transfer') }}
        </sdButton>
        <sdButton class="bill__button" @click="openModal('withdrawal')" size="large" type="primary" raised>
          {{ i18n.t('start.withdrawal') }}
        </sdButton>
      </div>
      <div class="bill__buttons" v-else>
        <sdButton class="bill__button" size="large" @click="openModal('buy')" type="success" raised>
          {{ i18n.t('start.buy') }}
        </sdButton>
        <a-tooltip placement="top" :title="i18n.t('accounts.token.send_is_blocked')">
          <sdButton class="bill__button" size="large" type="primary" raised>
            <!-- @click="openModal('send')" -->
            {{ i18n.t('start.sell') }}
          </sdButton>
        </a-tooltip>
      </div>
    </div>
    <a-skeleton active :avatar="{ shape: 'circle' }" :paragraph="{ rows: 2 }" v-else />
  </sdCards>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
const StartBills = {
  name: 'StartBills',
  props: {
    BillName: {
      type: String,
    },
    BillCount: {
      type: Number,
    },
    BillIcon: {
      type: String,
    },
    AccountId: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const router = useRouter();
    const openModal = (nameAction) => {
      emit('openModal');
      router.push(`/${nameAction}/${props.AccountId}`);
    };
    return { i18n, openModal };
  },
};
export default StartBills;
</script>
